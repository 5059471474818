<template>


      <b-row>
     


        <b-col  cols="12" sm="12" md="12" lg="12" xl="12" v-if="tipoDocumento === 'No'" align="center" style="padding:0px">

            <b-card>
  

            <img src="/img/ruc.svg" style="width: 80px;"/>

            <p style="margin-top:10px">Necesitas asociar un número RUC a tu negocio para desbloquear esta funcionalidad.</p>


            <b-button
                        variant="primary"
                        @click="asociar">

                                                Asociar número RUC al negocio

                                                </b-button>

             </b-card>
      
    
     
        </b-col>

        <b-col  cols="12" sm="12" md="12" lg="12" xl="12" v-else-if="tipoDocumento === 'RUC'" style="padding:0px">
          

                <b-row>

                    <b-col  cols="12" sm="12" md="1" lg="2" xl="2">


                    </b-col>

                    <b-col  cols="12" sm="12" md="10" lg="8" xl="8">

                        <password :user-id="userId" :token-auth="tokenAuth" :id-local="idLocal" :password="password" />
           
                    </b-col>

                    <b-col  cols="12" sm="12" md="1" lg="2" xl="2">


                    </b-col>

         

                      <b-col  cols="12" sm="12" md="12" lg="12" xl="12">

                      <listado-empleados :key="componentKeyListadoEmpleados" :user-id="userId" :token-auth="tokenAuth" :id-local="idLocal" />

                      </b-col>


                </b-row>
  
    
           


           
    
        </b-col>

        <b-col  cols="12" sm="12" md="12" lg="12" xl="12" v-else align="center" style="padding:0px">
            <b-card>
  
    
            <img src="/img/error.svg" style="width: 80px;"/>
                            <p class="errorTexto" style="margin-top:10px; font-size:14px;">
                         Esta funcionalidad solo está disponible para usuarios que tengan asociado un número de RUC a su negocio.
                         </p>

                         <p>Si desea asociar su número RUC a su negocio, contácte con soporte para solicitar dicha actualización.</p>


                         <a target="_blank" href="https://api.whatsapp.com/send?phone=19452074178&text=Hola,%20necesito%20actualizar%20mi%20documento%20de%20identidad%20de%20mi%20negocio"> <b-button
                        variant="primary"
                                                  
                                               
                                             
                                                >

                                                <svg width="18" height="18" style="margin-right: 15px;"  viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="#fff" ><path d="M13.601 2.326A7.854 7.854.0 007.994.0C3.627.0.068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933.0 003.79.965h.004c4.368.0 7.926-3.558 7.93-7.93A7.898 7.898.0 0013.6 2.326zM7.994 14.521a6.573 6.573.0 01-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251A6.56 6.56.0 011.407 7.922c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56.0 014.66 1.931 6.557 6.557.0 011.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729.0 00-.529.247c-.182.198-.691.677-.691 1.654.0.977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/></svg> Contactar con soporte

                                                </b-button></a>

              </b-card>
        </b-col>
  
        
      </b-row>


         
      
      </template>
      
      <script>
      import {
        BButton, BRow, VBPopover, BCol, BCard,
      } from 'bootstrap-vue'
      import Password from './Password.vue'
      import ListadoEmpleados from './ListadoEmpleados.vue'
      
      
      export default {
        components: {
          ListadoEmpleados,
            Password,
          BButton,
          BRow,
          BCol,
          BCard,
  
        },
        directives: {
          'b-popover': VBPopover,
        },
        props: ['userId', 'tokenAuth', 'idLocal','tipoDocumento', 'password'],
        data() {
      
      
          return {
            componentKeySolicitudes:0,
            componentKeyListadoEmpleados:0,
      
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        mounted() {


          

          this.$eventBus.$on('reiniciarListadoEmpleados', () => {
      this.componentKeyListadoEmpleados += 1

    })

          this.$eventBus.$on('reiniciarSolicitudesIngresoNegocio', () => {
      this.componentKeySolicitudes += 1

    })

      
        },
        methods: {
  
            asociar(){
                this.$eventBus.$emit('clickGeneral')     

            }
      
         
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      