<template>

    <b-card>

    <b-row>

      
<b-modal
        ref="modalPassword"
        centered
        hide-footer
        size="sm"
        title="Actualizar contraseña de ingreso"
      >
        <div class="d-block text-center">
  
          <b-form
            autocomplete="off"
            @submit.prevent="onSubmit"
          >
  
            <b-row>
  
              <b-col
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                      style="margin-bottom: 10px;"
                    >


                    <p class="textLabel" style="text-align: left;margin-top: 0px;margin-bottom: 5px;">
                        Ingrese la contraseña de ingreso (*):</p>

                        <b-form-input
                                 v-model="passwordInput"
                                 autofocus
                                 placeholder="Ingrese la contraseña de ingreso"
                                 required
                                 type="text"
                               />
                    

                    </b-col>
  
              <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
  
                <p
                  class="textLabel"
                  style="text-align: center;margin-bottom: 10px;"
                >
                  Ingrese su código PIN (*): <feather-icon
                                    icon="HelpCircleIcon"
                                    size="16"
                                      v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                    title="Código PIN de seguridad"
                                    style="margin-right: 5px;cursor:pointer;"
                                    /> </p>
  
                <PincodeInput
                  v-model="pin"
                  placeholder="-"
                  :length="6"
                  :autofocus="false"
                  :secure="true"
                  :disabled="isActive"
                  required
                />
  
              </b-col>
  
              <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
  
                <b-button
                  id="btn_password"
                  ref="btn_password"
                  type="submit"
                  variant="primary"
                  :disabled="isActive"
                  block
                >
  
                  Actualizar contraseña
  
                </b-button>
  
              </b-col>
  
            </b-row>
          </b-form>
  
        </div>
  
      </b-modal>




        <b-col cols="4" sm="4" md="4" lg="4" xl="4" align="center" style="display:flex">

            <div style="margin:auto">

                <b-avatar
                        size="80"
                        variant="light-primary"
                    >

                        <span class="d-flex align-items-center">
                          
                            <img src="/img/group.svg" style="width: 50px;"/>
                        
                        </span>

                        </b-avatar>

            </div>

            
      



        </b-col>

        <b-col cols="8" sm="8" md="8" lg="8" xl="8">

            <div v-if="passwordLabel === 'No'">

                <p style="text-align:center; font-weight: bold;margin-bottom:5px; font-size:16px">Úne gente a tu negocio</p>
                <p style="text-align:center;font-size:12px">Permite que otros usuarios de EQCoop formen parte de tu negocio. Registra una contraseña de ingreso y compártelo con tus empleados. </p>

                <b-button 
                    @click="openModalPassword"
                    variant="outline-secondary"
                    size="md"
                    block
                    style="margin-top:0px; margin-bottom:0px"
                  
                  >
                  <feather-icon

                    icon="LockIcon"
                    size="14"
                    style= "margin-right:2px"
                    />
            
                    Registrar contraseña de negocio

                  </b-button>
            </div>

            <div v-else>



        <p style="text-align: center;font-size: 14px; margin:5px">
          Comparte esta contraseña con tus empleados para que se unan a tu negocio
        </p>

        <b-input-group>

            <b-form-input
                  v-model="passwordLabel"
                  readonly
                  />

              <b-input-group-append>
                <b-dropdown
                variant="outline-primary"
                right
              >

              <template  #button-content>
                    
                    Acciones <feather-icon
                      icon="ChevronDownIcon"
                      size="16"
                      style="color:white"
                      />

                    </template>
                  <b-dropdown-item  @click="onCopy(passwordLabel)" >  <feather-icon
                      icon="CopyIcon"
                      size="15"
                      style="margin-right: 10px;"
                      /> Copiar contraseña </b-dropdown-item>
                 
                    <b-dropdown-item     @click="openModalPassword">  
                    <feather-icon
                                icon="LockIcon"
                                size="18"
                                style="margin-right: 10px;"
                              
                                />Cambiar contraseña</b-dropdown-item>

                              
                </b-dropdown>
              </b-input-group-append>

              </b-input-group>
              


       


                </div>



        </b-col>
        
    </b-row>

    </b-card>
      
      </template>
      
      <script>
      import {
        BButton, BRow, VBPopover, BCol, BCard, BAvatar, BForm, BDropdown,BDropdownItem,BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup,
      } from 'bootstrap-vue'
      
          
      import PincodeInput from 'vue-pincode-input'
      
      
      export default {
        components: {
          BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup,
          BDropdown,BDropdownItem,
          BForm,
          PincodeInput,
            BAvatar,
          BButton,
          BRow,
          BCol,
          BCard,
  
        },
        directives: {
          'b-popover': VBPopover,
        },
        props: ['userId', 'tokenAuth', 'idLocal','password'],
        data() {
      
      
          return {

            pin:"",
            passwordInput:"",
            isActive:false,
            passwordLabel: this.password
      
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        mounted() {
      
        },
        methods: {

          onCopy(copy) {
            navigator.clipboard.writeText(copy);

          this.$toast.success('Contraseña copiado exitosamente', {
            position: 'top-right',
            timeout: 1500,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        },
        onError() {
          this.$toast.error('Falló en copiar la contraseña', {
            position: 'top-right',
            timeout: 1500,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        },  
  
          openModalPassword(){
          
            this.pin="";
            this.passwordInput="";
           this.$refs.modalPassword.show()

          },

     onSubmit(event) {
      event.preventDefault();



        this.isActive=true;
      
        const local_json = {
           userId: this.userId, password: this.passwordInput, pin: this.pin, id: this.idLocal
          }

        



        const local_string = JSON.stringify(local_json)
  
        const dataEncripted = this.$encryptBackoffice.encrypt(local_string)
 
        document.getElementById('btn_password').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Actualizando'

     
      this.$https.post('/locals/updatePassword/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
        document.getElementById('btn_password').innerHTML = 'Actualizar contraseña';
        this.isActive=false;
      
    
      if (response.data.code == 200) {

       

     
        this.passwordLabel= this.passwordInput;

     
        this.$refs.modalPassword.hide();

        this.$toast.success(response.data.message, {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        });

      

      } else {

        this.$toast.error(response.data.message, {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })

      if (response.data.code == 401) {

      

        localStorage.removeItem('userData')


        this.$router.push({ name: 'auth-login' })
      } 
      }
      }).catch(error => {
        this.$toast.error("Ocurrio un error " + error, {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      })

          


    },
         
   
      
         
         
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      